import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/layout';
import Seo from '@components/seo';
import CMSHeaderMinimal from '@components/HeaderMinimal/HeaderMinimalV2';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import {
    ChapterUSP,
    SocialProof,
    ProviderList,
    ChapterPricing,
    RelatedContentWithImg,
    CommonActionButton,
    GenericIcon,
    UspBanners,
    ChapterEntries,
    //PartnerAndInsuranceCompany,
} from '@components/CMSComponents';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '@atoms/Button/Button';
import Section from '@atoms/Section/Section';
import TitleGroup from '@atoms/TitleGroup/TitleGroup';
import VideoBackgroundWithContent from '@components/VideoBackgroundWithContent';
import Footer from '@components/Footer/Footer';
import KeyFeaturesSlider from '@components/KeyFeaturesSlider';
import SingleAccordion from '@components/SingleAccordion/SingleAccordion';
import { UserLang } from '@constants/common';

import './home.scss';
import useLocale from '../hooks/useLocale';
import LanguageNotSupported from '../components/LanguageNotSupported/LanguageNotSupported';
import GetStartedSteps from '../components/CMSComponents/GetStartedSteps';
import { withLocale } from '@hocs';

export const query = graphql`
    fragment HomeField on AEPSYCMS_Homepage {
        seoTitle
        seoDescription
        header {
            title
            subtitle
            ctaUrl
            ctaLabel
            secondaryCtaUrl
            secondaryCtaLabel
        }
        chapterEntries {
            title
            url
            images {
                media
                src {
                    data {
                        attributes {
                            url
                            urlSharp {
                                childImageSharp {
                                    fluid(
                                        maxWidth: 250
                                        toFormat: WEBP
                                        webpQuality: 100
                                    ) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        uspBanners {
            button {
                label
            }
            icon
        }
        socialProof {
            author
            quote
            comments {
                text
            }
            audio {
                data {
                    attributes {
                        url
                    }
                }
            }
            audioMoreInfo {
                data {
                    attributes {
                        component {
                            ... on AEPSYCMS_ComponentCommonCardInfoList {
                                title
                                content
                                image {
                                    data {
                                        attributes {
                                            url
                                            urlSharp {
                                                childImageSharp {
                                                    fluid(
                                                        maxWidth: 150
                                                        toFormat: WEBP
                                                    ) {
                                                        ...GatsbyImageSharpFluid
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        providersSlider {
            ...ProvidersSlider
        }
        stepByStep {
            title
            subtitle
            list {
                title
                subtitle
                media {
                    data {
                        attributes {
                            url
                            ext
                        }
                    }
                }
            }
            ctaUrl
            ctaLabel
        }
        chapterUsp {
            ...ChapterUsp
        }
        pricing {
            title
            subtitle
            pricingTitle
            pricingSubtitle
            secondaryCtaLabel
            secondaryCtaUrl
            list {
                text
            }
            ctaUrl
            ctaLabel
            images {
                media
                src {
                    data {
                        attributes {
                            url
                            urlSharp {
                                childImageSharp {
                                    fluid(
                                        maxWidth: 300
                                        toFormat: WEBP
                                        webpQuality: 90
                                        fit: CONTAIN
                                    ) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        faq {
            data {
                attributes {
                    key
                    qar {
                        id
                        question
                        answer
                    }
                    title
                    subtitle
                }
            }
        }
        aepsyUsp {
            data {
                attributes {
                    component {
                        ... on AEPSYCMS_ComponentCommonAepsyUsp {
                            title
                            subtitle
                            content
                            icon
                        }
                    }
                }
            }
        }
        partners {
            title
            subtitle
            text
            images {
                data {
                    attributes {
                        url
                    }
                }
            }
            companies {
                label
            }
        }
        relatedContent {
            title
            components {
                data {
                    attributes {
                        component {
                            ... on AEPSYCMS_ComponentAtomsImageButton {
                                button {
                                    label
                                    to
                                }
                                images {
                                    media
                                    src {
                                        data {
                                            attributes {
                                                url
                                                urlSharp {
                                                    childImageSharp {
                                                        fluid(
                                                            maxWidth: 250
                                                            toFormat: WEBP
                                                            webpQuality: 90
                                                            fit: CONTAIN
                                                        ) {
                                                            ...GatsbyImageSharpFluid
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        aepsyCms {
            homepageDE: homepage(locale: "de-CH") {
                data {
                    attributes {
                        ...HomeField
                    }
                }
            }
            homepageEN: homepage(locale: "en") {
                data {
                    attributes {
                        ...HomeField
                    }
                }
            }
            homepageFR: homepage(locale: "fr-CH") {
                data {
                    attributes {
                        ...HomeField
                    }
                }
            }
            homepageIT: homepage(locale: "it") {
                data {
                    attributes {
                        ...HomeField
                    }
                }
            }
        }
    }
`;
const HomePage = ({ data: graphqlData }) => {
    const { currentLanguage } = useLocale();

    const homepage = useMemo(() => {
        const {
            aepsyCms: { homepageDE, homepageEN, homepageFR, homepageIT },
        } = graphqlData;
        switch (currentLanguage) {
            case UserLang.English:
                return homepageEN;
            case UserLang.French:
                return homepageFR;
            case UserLang.Italian:
                return homepageIT;
            case UserLang.German:
            default:
                return homepageDE;
        }
    }, [graphqlData]);

    const renderHeaderBtnSecondary = (
        secondaryCtaLabel?: string,
        secondaryCtaUrl?: string
    ) => {
        if (!secondaryCtaLabel || !secondaryCtaUrl) return <></>;

        return (
            <AnchorLink offset={() => 40} href={secondaryCtaUrl}>
                <Button
                    variant="naked"
                    theme="white"
                    label={header.secondaryCtaLabel}
                    align="center"
                    size="m"
                />
            </AnchorLink>
        );
    };

    if (!homepage?.data) {
        return (
            <Layout>
                <div className="global_theme-green HomePage">
                    <Section theme="dark">
                        <HeaderNav theme="white" />
                        <CMSHeaderMinimal title="" subtitle="" spacing="xs" />
                    </Section>
                    <Section spacingBottom="m">
                        <LanguageNotSupported />
                    </Section>
                    <Footer />
                </div>
            </Layout>
        );
    }

    const {
        data: {
            attributes: {
                seoTitle,
                seoDescription,
                header,
                socialProof,
                providersSlider,
                stepByStep,
                chapterUsp,
                pricing,
                faq,
                aepsyUsp,
                uspBanners,
                chapterEntries,
                partners,
                relatedContent,
            },
        },
    } = homepage;

    const pricingDesktopImage = pricing?.images.find(
        (item: any) => item.media === 'desktop'
    );
    const pricingMobileImage = pricing?.images.find(
        (item: any) => item.media === 'mobile'
    );

    return (
        <Layout>
            <Seo title={seoTitle} description={seoDescription} />
            <div className="global_theme-green HomePage">
                <Section theme="dark">
                    <HeaderNav theme="white" />
                    <CMSHeaderMinimal
                        title={header.title}
                        subtitle={header.subtitle}
                        spacing="xs"
                        btnPrimary={
                            <CommonActionButton
                                cta={{
                                    ctaLabel: header.ctaLabel,
                                    ctaUrl: header.ctaUrl,
                                }}
                            />
                        }
                    />
                    <div
                        className="HomePage--headerInner"
                        id={
                            header?.secondaryCtaUrl?.includes('#')
                                ? header?.secondaryCtaUrl?.replace('#', '')
                                : 'ChapterPage'
                        }
                    >
                        {chapterEntries && (
                            <ChapterEntries list={chapterEntries} />
                        )}
                        <div className="g_show_only_mobile HeaderMinimalV2--mainButtons">
                            <div className="HeaderMinimal--btnPrimary">
                                <CommonActionButton
                                    cta={{
                                        ctaLabel: header.ctaLabel,
                                        ctaUrl: header.ctaUrl,
                                    }}
                                />
                            </div>
                            <div>
                                {renderHeaderBtnSecondary(
                                    header.secondaryCtaLabel,
                                    header.secondaryCtaUrl
                                )}
                            </div>
                        </div>
                        {uspBanners && (
                            <Section spacingTop="s">
                                <UspBanners list={uspBanners} />
                            </Section>
                        )}

                        {socialProof && (
                            <SocialProof
                                {...socialProof}
                                audioUrl={
                                    socialProof.audio?.data?.attributes.url
                                }
                                audioMoreInfo={socialProof.audioMoreInfo?.data}
                            />
                        )}
                    </div>
                </Section>
                <div>
                    <>
                        {providersSlider && (
                            <ProviderList {...providersSlider} />
                        )}
                        {chapterUsp && (
                            <>
                                <Section container="large">
                                    <TitleGroup
                                        align="desktopCenter"
                                        title={chapterUsp.title}
                                        subtitle={chapterUsp.subtitle}
                                    />
                                </Section>
                                <Section spacingTop="s" spacingBottom="s">
                                    <ChapterUSP
                                        uspList={chapterUsp.list}
                                        cta={{
                                            ctaLabel: chapterUsp.ctaLabel,
                                            ctaUrl: chapterUsp.ctaUrl,
                                        }}
                                    />
                                </Section>
                            </>
                        )}
                    </>
                    <>
                        {stepByStep && (
                            <Section spacingBottom="m">
                                <GetStartedSteps {...stepByStep} />
                            </Section>
                        )}
                        {pricing && (
                            <ChapterPricing
                                {...pricing}
                                desktopImage={
                                    pricingDesktopImage
                                        ? pricingDesktopImage.src.data
                                              .attributes.url
                                        : undefined
                                }
                                desktopSharpImage={
                                    pricingDesktopImage
                                        ? pricingDesktopImage.src.data
                                              .attributes.urlSharp
                                        : undefined
                                }
                                mobileImage={
                                    pricingMobileImage
                                        ? pricingMobileImage.src.data.attributes
                                              .url
                                        : undefined
                                }
                                mobileSharpImage={
                                    pricingDesktopImage
                                        ? pricingDesktopImage.src.data
                                              .attributes.urlSharp
                                        : undefined
                                }
                            />
                        )}

                        {/* Not ready for release yet (15.dec 2023) */}
                        {/* {partners && (
                                <Section spacingTop="l" spacingBottom="m">
                                    <PartnerAndInsuranceCompany {...partners} />
                                </Section>
                            )} */}
                    </>
                    <>
                        {aepsyUsp.data && (
                            <div className="HomePage--KeyFeaturesSlider">
                                <KeyFeaturesSlider
                                    list={aepsyUsp?.data?.attributes?.component?.map(
                                        ({
                                            title,
                                            subtitle,
                                            content,
                                            icon,
                                        }: any) => ({
                                            title,
                                            subtitle,
                                            text: content,
                                            icon: <GenericIcon icon={icon} />,
                                        })
                                    )}
                                />
                            </div>
                        )}

                        {relatedContent && (
                            <RelatedContentWithImg {...relatedContent} />
                        )}
                    </>

                    {faq.data ? (
                        <Section container="short" spacingTop="m">
                            <TitleGroup
                                title={faq.data.attributes.title}
                                subtitle={faq.data.attributes.subtitle}
                                align="desktopCenter"
                            />
                            <Section spacing="s">
                                <SingleAccordion
                                    data={faq.data.attributes.qar.map(
                                        (item: any) => ({
                                            ...item,
                                            isMarkdown: true,
                                        })
                                    )}
                                    comingFrom="chapter"
                                />
                            </Section>
                        </Section>
                    ) : null}
                </div>
                <Section container="large" spacingBottom="m" spacingTop="s">
                    <VideoBackgroundWithContent />
                </Section>
                <Footer />
            </div>
        </Layout>
    );
};

export default withLocale(HomePage);
