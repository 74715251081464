import Section from '@atoms/Section/Section';
import TitleGroup from '@atoms/TitleGroup/TitleGroup';
import React from 'react';
import Module from '@atoms/Module/Module';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import './GetStartedSteps.scss';
import { Grid, Box } from '@material-ui/core';
import CommonActionButton from './CommonActionButton';

const GetStartedSteps = ({ list, ctaUrl, ctaLabel, title, subtitle }) => {
    return (
        <>
            <Section container="large" spacingTop="m">
                <TitleGroup
                    title={title}
                    subtitle={subtitle}
                    align="desktopCenter"
                />
            </Section>
            <Section container="short">
                <div className="GetStartedSteps">
                    <Grid container spacing={4}>
                        {list.map((item: any, index: number) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <div
                                    className={`GetStartedSteps--card card-${
                                        index + 1
                                    }`}
                                >
                                    <div className="GetStartedSteps--title">
                                        <Title
                                            theme="grey"
                                            size="giant"
                                            tag="span"
                                            font="alt"
                                        >
                                            {index + 1}
                                        </Title>
                                    </div>
                                    <Module radius="giant">
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            sx={{ mb: 3 }}
                                        >
                                            <div className="GetStartedSteps--video">
                                                <video
                                                    playsInline
                                                    autoPlay
                                                    muted
                                                    loop
                                                >
                                                    <source
                                                        src={
                                                            item.media?.data
                                                                ?.attributes.url
                                                        }
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            </div>
                                        </Box>

                                        <div>
                                            <Title size="m" align="center">
                                                {item.title}
                                            </Title>
                                            <Text size="m" align="center">
                                                {item.subtitle}
                                            </Text>
                                            {index === 0 && (
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                >
                                                    <CommonActionButton
                                                        cta={{
                                                            ctaUrl,
                                                            ctaLabel,
                                                            ctaProps: {
                                                                align: 'center',
                                                                theme:
                                                                    'primary',
                                                                variant:
                                                                    'inline',
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                        </div>
                                    </Module>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Section>
        </>
    );
};

export default GetStartedSteps;
